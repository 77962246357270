<template>
  <div>
    <v-tooltip
      left
      color="primary"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          v-on="on"
          @click="newEntry()"
        >
          <v-icon
            color="primary"
          >
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Email Template</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog
      v-model="show"
      persistent
      max-width="800"
    >
      <v-card
        flat
      >
        <v-card-title class="text-h5 secondary--text">
          Email Details
          <v-spacer />
          <v-btn
            text
            rounded
            outlined
            @click="cancelEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-times
            </v-icon>Close
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form
            ref="entryForm"
            v-model="valid"
            lazy-validation
          >
            <v-container fluid>
              <v-row dense>
                <!--Send Date-->
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="date_send"
                        label="Send Date"
                        placeholder=" "
                        prepend-icon="fal fa-calendar-alt"
                        required
                        :rules="rules.date_send"
                        v-on="on"
                        @blur="date1 = $_parseDate(date_send)"
                      />
                    </template>
                    <v-date-picker
                      v-model="date1"
                      color="primary"
                      @input="menu = false"
                    />
                  </v-menu>
                </v-col>

                <!--Send Time-->
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-text-field
                    v-model="time_send"
                    label="Time (24 Hour Time 00:00:00)"
                    placeholder=" "
                    required
                    :rules="rules.time_send"
                  />
                </v-col>

                <!--Mode-->
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <v-autocomplete
                    v-model="flag_mode"
                    :items="valueListsStore.emailMode"
                    label="Mode"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="flag_mode === 0 ? [] : rules.flag_mode"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Projects-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="projects"
                    :items="projectsStore.projectsValueList"
                    label="Projects"
                    placeholder=" "
                    item-value="id"
                    item-text="code"
                    clearable
                    multiple
                    chips
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--User Types-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="user_type"
                    :items="valueListsStore.userTypes"
                    label="Users"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    multiple
                    chips
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Subject-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="subject"
                    label="Subject"
                    placeholder=" "
                    required
                    :rules="rules.subject"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Body-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-textarea
                    v-model="body"
                    label="Message (HTML Format)"
                    placeholder=" "
                    required
                    :rules="rules.body"
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="mode !== 1"
                dense
              >
                <!--Status-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.emailStatus"
                    label="Status"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                    required
                    :rules="flag_status === 0 ? [] : rules.flag_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions class="mx-4 pb-4">
          <v-btn
            block
            rounded
            color="primary"
            @click="saveEntry()"
          >
            <v-icon
              small
              left
            >
              fal fa-check
            </v-icon>Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import {supportEmail} from '@/env'
import { mixin } from '@/mixins/mixin.js';
import TextDisplay from '@/components/TextDisplay';

const { mapFields } = createHelpers({
  getterType: 'emailsStore/getEntryAdminField',
  mutationType: 'emailsStore/updateEntryAdminField',
});

export default {
  name: 'Email',
  components: {
    TextDisplay,
  },
  mixins: [mixin],
  data() {
    return {
      menu:false,
      rules: {
        date_send: [
			    v => !!v || 'Required',
        ],
        time_send: [
			    v => !!v || 'Required',
        ],
        subject: [
			    v => !!v || 'Required',
        ],
		    body: [
			    v => !!v || 'Required',
        ],
        flag_mode: [
           v => !!v || 'Required',
        ],
        flag_status: [
			    v => !!v || 'Required',
        ],
      },
    }
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      emailsStore: state => state.emailsStore,
      projectsStore: state => state.projectsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'show',
      'valid',
      'mode',

      'date_send',
      'time_send',
      'subject',
      'body',
      'user_type',
      'flag_mode',
      'flag_status',
      'flag_show',
      'date1',
      'projects'
    ]),

  },

  watch: {
    date1() {
      this.date_send = this.$_formatDate(this.date1);
    },
  },

  methods: {

    async newEntry() {
      this.mode = 1;
      await this.$store.dispatch('projectsStore/valueList');
      await this.$store.dispatch('emailsStore/resetEntryAdmin');
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        if(this.requirements()) {
          const entry = this.$store.getters['emailsStore/getEntryAdmin'];
          /* Create */
          if (this.mode === 1) {
            await this.$store.dispatch('emailsStore/create', entry);
          }
          /* Edit */
          else {
            await this.$store.dispatch('emailsStore/update', entry);
          }
          this.cancelEntry();
        }
      }
    },

    requirements() {
      if(this.projects.length === 0 ){
        /*Show message*/
        let message = {
          color: 'error',
          text: 'Select Project(s) before continuing.',
        };
        this.$store.commit('messagesStore/setMessage', message);
        return false;
      }
      else if(this.user_type.length === 0) {
        /*Show message*/
        let message = {
          color: 'error',
          text: 'Select User Type(s) before continuing.',
        };
        this.$store.commit('messagesStore/setMessage', message);
        return false;
      }
      return true;
    }

  },
}
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>