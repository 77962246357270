<template>
  <v-container
    class="py-2"
    :class="reduceX === 1 ? 'px-0' : ''"
    fluid
  >
    <v-row dense>
      <v-col
        cols="12"
        sm="12"
      >
        <div class="text-h5 secondary--text">
          {{ titleValue }}
        </div>
        <div
          v-if="subtitleValue"
          class="text-overline secondary--text"
        >
          {{ subtitleValue }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    titleValue: {
      type: String,
      default: '',
    },
    subtitleValue: {
      type: String,
      default: '',
      required: false,
    },
    reduceX: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style scoped>

</style>
